<template>
  <div class="container" :style="colorObject">
    <header class="border-bottom">
      <div class="d-flex justify-content-between align-items-center p-2 px-3">
        <router-link class="logo" :to="{ name: 'LiffShopProducts' }">
          <img :src="meta.image" />
        </router-link>

        <div>
          <a class="p-2 btn">
            <img src="./images/icon-user.svg" />
          </a>

          <router-link
            :to="{ name: 'LiffShopCart' }"
            class="p-2 btn position-relative"
          >
            <img src="./images/icon-cart.svg" />
            <small class="badge badge-pill badge-danger position-absolute"
              >1</small
            >
          </router-link>

          <button class="p-2 btn" @click="isSearching = !isSearching">
            <img src="./images/icon-search.svg" />
          </button>
        </div>
      </div>

      <div
        v-if="isSearching"
        class="search px-3 py-2 d-flex align-items-center shadow shadow-sm"
      >
        <div class="search-box p-2 px-3 flex-fill d-flex align-items-center">
          <img src="./images/icon-search.svg" class="icon mr-2" />
          <input type="text" placeholder="搜尋" class="box" />
        </div>
        <button
          class="btn text-black text-nowrap font-weight-bolder"
          @click="isSearching = !isSearching"
        >
          取消
        </button>
      </div>
    </header>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { themeColorFn } from "@/mixins/liff/themeColor";

export default {
  mixins: [themeColorFn({ themeConfigPage: "shop" })],
  data() {
    return {
      isSearching: false,
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
      getModule: "liffModule/getModule",
    }),
    moduleConfig() {
      return this.getModule("liff_checkin").module_config || {};
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";
@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);

.container {
  max-width: 768px;
  padding: 0;

  font-family: "Noto Sans TC", "Helvetica";
  background-color: var(--liff-layout_bg_color);
}

a {
  color: var(--s-primary);
}

header {
  position: relative;
  .logo {
    max-height: 40px;
    max-width: 40vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      //max-height: 100px;
      max-width: 100%;
    }
  }

  .badge {
    padding: 2px 4px;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(0.8);
  }

  .search {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    background-color: white;

    .search-box {
      background-color: #eceff180;
      border-radius: 8px;
      .icon {
        width: 14px;
      }
      .box {
        border: none;
        background: none;
        font-size: 14px;
      }
    }
  }
}

.modal {
  .modal-header {
    padding: 1rem;
    border-bottom: 0;
    display: block;
    position: relative;

    h5 {
      font-size: 17px;
      font-weight: 700;
    }

    .close {
      padding: 1rem;
      opacity: 1;
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.btn {
  border-width: 2px;
  padding: 0.8rem;
  font-weight: 500;
  border-radius: 0.5rem;

  &.btn-primary {
    background-color: var(--s-primary);
    border-color: var(--s-primary);
  }
}

.price {
  font-size: 16px;
  color: #ff1744;
  font-weight: 700;
}

.oprice {
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
  color: #9e9e9e;
}

.product {
  &__photo {
    aspect-ratio: 1/1;
    img {
      border-radius: 8px;
      width: 100%;
    }
  }

  &__title {
    font-size: 15px;
    font-weight: 700;
  }
}

.field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-primary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 14px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;
    &::placeholder {
      color: #b7b7b7;
    }
    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }
    &.invalid {
      border: 1px solid #fe0000;
    }

    &:disabled {
      color: var(--s-gray);
      background-color: var(--s-gray-light);
    }
  }

  &--required {
    &:after {
      content: "*";
      position: relative;
      font-family: "PingFang TC";
      color: var(--s-primary);
      margin-left: 2px;
    }
  }
}

.tab {
  .nav-link {
    color: var(--s-dark);
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: var(--liff-layout_bg_color);
    font-size: 15px;
    font-weight: 700;
    &.active {
      color: var(--s-primary);
      border-bottom-color: var(--s-primary);
    }
  }
}

.product-carousel {
  border-top: solid 1px #eee;
  padding-bottom: 1.5rem !important;

  ::v-deep .swiper-wrapper {
    align-items: stretch !important;
  }

  &__item {
    width: 120px !important;
    height: auto  !important;

    &__title {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    &__purchase {
      background-color: #0d47a11a;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
</style>
